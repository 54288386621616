<template>
  <div>
    <div class="bg-white shadow-sm rounded-lg p-4">
      <div class="box-title">
        <h4>Birthday Notification</h4>
        <br />
      </div>
      <el-form :model="form" ref="form" :hide-required-asterisk="true">
        <div class="row">
          <div class="col-4">
            <el-form-item :label="$t('Status')" prop="status">
              <el-select
                v-model="form.status"
                placeholder="Select Status"
                class="w-100"
              >
                <el-option value="1" label="Active">Active</el-option>
                <el-option value="0" label="Inactive">Inactive</el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col-4">
            <figure class="figure">
              <picture-input
                ref="pictureInput"
                margin="0"
                width="400"
                height="400"
                accept="image/jpeg,image/png"
                size="10"
                :removable="true"
                button-class=" btn btn-primary"
                class="mb-2"
                :custom-strings="{
                  drag:
                    'Drag a new birthday image or click here to select a file',
                }"
                @change="onImageChange"
              >
              </picture-input>
              <figcaption class="figure-caption">
                New Birthday Image.
              </figcaption>
            </figure>
          </div>
          <div class="col-4">
            <figure class="figure">
              <img
                src="@/assets/image/sorry-image-not-available.png"
                width="400"
                heigth="400"
                class="mb-2"
              />
              <figcaption class="figure-caption">
                Current Birthday Image.
              </figcaption>
            </figure>
          </div>
        </div>

        <el-button type="primary">Update Birthday Notification</el-button>
      </el-form>
    </div>
    <div class="bg-white shadow-sm rounded-lg p-4 mt-5">
      <div class="box-title">
        <h4>Scheduled Notifications</h4>
        <br />
      </div>
      <el-table :data="[]" class="w-100">
        <el-table-column
          prop="name"
          label="Name"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="date"
          label="Date"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="repetitive"
          label="Repetitive"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column prop="image" label="Image" width="120">
          <template slot-scope="scope">
            <a target="_blank" :href="scope.row.image">
              <img
                class="img-notification"
                :src="scope.row.image"
                alt="Forest"
              />
            </a>
          </template>
        </el-table-column>
        <el-table-column width="200" label="Operations">
          <template slot="header" slot-scope="scope">
            <el-input
              v-model="search"
              placeholder="Search..."
              class="p-0"
              :key="scope.row"
            />
          </template>
          <template slot-scope="scope">
            <center>
              <el-button-group>
                <el-button
                  size="mini"
                  icon="el-icon-delete"
                  @click="remove(scope.row, scope.$index)"
                ></el-button>
              </el-button-group>
            </center>
          </template>
        </el-table-column>
      </el-table>
      <el-button type="primary" class="mt-4"
        >New Scheduled Notification</el-button
      >
    </div>
  </div>
</template>

<script>
import notification from "@/services/api/Notification";
import PictureInput from "vue-picture-input";
export default {
  data() {
    return {
      form: {
        status: "0",
        image: null,
      },
      tableData: [],
      search: "",
    };
  },
  components: { PictureInput },
  mounted() {},
  methods: {
    onImageChange(image) {
      if (image) {
        console.log("Picture loaded.");
        this.form.image = image;
      } else {
        console.log("FileReader API not supported: use the <form>, Luke!");
      }
    },
  },
};
</script>

<style>
.btn-edit-image {
  border: 1px solid black;
}
</style>